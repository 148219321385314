const $$ = {
  body: document.querySelector("body"),

  wrapper: document.getElementById("wrapper"),

  header: document.getElementById("header"),

  nav: document.getElementById("nav"),

  hero: document.getElementById("hero"),

  main: document.getElementById("main"),

  containerCentre: document.getElementById("container-centre"),

  containerRight: document.getElementById("container-right"),

  preFooter: document.getElementById("pre-footer"),

  footer: document.getElementById("footer"),

  mobileNav: document.getElementById("mobile-nav"),

  mobileNavToggle: document.getElementById("mobile-nav-toggle"),
};

module.exports = $$;
